import React,{useState,useEffect}from "react"
import {graphql ,Link } from "gatsby"
import Layout from "@components/layout"
import Meta from "@components/meta"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/news.scss'
import NewsItem from "@components/news-item"
import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)

const NewsPage = (props) => {
  const [path, setPath] = useState(false);
  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })

  return (
    <Layout>
      <Meta
        title="お知らせ"
      />
      <PoseGroup>
        <Box key={"news"} pose={path ? "visible" : "hidden"}>
        <section class="page">
          <div class="page-top">
            <div class="page-top__container">
              <ol>
                <li>
                  <Link
                    to="/">
                      TOP&nbsp;>&nbsp;
                    </Link>
                  </li>
                <li>お知らせ</li>
              </ol>
              <h2 class="page-top__heading">NEWS</h2>
              <p class="page-top__lead">お知らせ</p>
            </div>
          </div>
          <div class="section page-section">
            <div class="section__container">
              <ul>
              {props.data.allMicrocmsNews.edges.map(({ node }) => (
                <NewsItem id={node.newsId} key={node.newsId} link={node.newsId} datetime={node.publishedAt} txt={node.title}/>
              ))}
              </ul>
              {/* <p class="btn btn--small btn--small--solid"><a href="#"><span>次へ</span><RightArrowIcon /></a></p>
              <p class="btn btn--small btn--small--solid prev"><a href="#"><span>前へ</span><RightArrowIcon /></a></p> */}
            </div>
          </div>
        </section>
        <BottomCta/>
        </Box>
      </PoseGroup>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
query {
  allMicrocmsNews {
    edges {
      node {
        id
        title
        updatedAt
        body
        publishedAt
        newsId
      }
    }
  }
}
`